import React, { useContext } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import AppLayout from "../layout/AppLayout";
import Dashboard from "../../views/dashboard";
import Login from "../../views/auth/SignIn";
import Meeting from "views/meeting";
import BulkMeetingForm from "views/meeting/BulkMeetingForm";
import Reports from "views/reports";
import ReportList from "views/reports/ReportList";
import { AuthContext } from '../../shared/context/auth-context';

const Routing = () => {
  const auth = useContext(AuthContext);

  return (
    <main>
      <Routes>
        {auth.isLoggedIn ? (
          <>
            {/* Protected routes wrapped with AppLayout */}
            <Route element={<AppLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/meetings" element={<Meeting />} />
              <Route path="/meetings/:keyword" element={<Meeting />} />
              <Route path="/add-bluk-meetings" element={<BulkMeetingForm />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/filter" element={<ReportList />} />
              
              {/* Redirect root to dashboard when logged in */}
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
            </Route>
            
            {/* Catch-all route for logged-in users */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </>
        ) : (
          <>
            {/* Public routes */}
            <Route path="/" element={<Login />} />
            
            {/* Catch-all route for non-logged-in users */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Routes>
    </main>
  );
};

export default Routing;
