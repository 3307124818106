
import React, { useContext, useState, useEffect, useRef } from 'react';
import {
    Input,
    Select,
    DatePicker,
    TimeInput,    
    FormItem,
    FormContainer,
    Alert,
    Spinner,
    Radio,
    Dialog,
} from 'components/ui'

import { Field, Form, Formik } from 'formik'
import CreatableSelect from 'react-select/creatable'
import * as Yup from 'yup'
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import dateFormat from 'dateformat';
import { useAuth } from "../../shared/hooks/auth-hook";

     
const MeetingMedium = [
    { value: 'skype', label: 'Skype' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'meet', label: 'Meet' },
    { value: 'phone', label: 'Phone' },
    { value: 'office', label: 'Office' },
]

    
const validationSchema = Yup.object().shape({
    
    clientName: Yup.string().required('Please Select Client Name.'),
    projectName: Yup.string().required('Please Select Project Name.'),
    estimateOrAssignment: Yup.boolean().required('Please select one!'),
    estimateName: Yup.string().when('estimateOrAssignment', {
        is: true,
        then: Yup.string().required('Please Select Estimate.')
      }),
    assignmentName: Yup.string().when('estimateOrAssignment', {
        is: false,
        then: Yup.string().required('Please Select Assignment.')
    }), 
    meetingTitle: Yup.string().required('Please Enter Meeting Title.'),
    meetingDuration: Yup.string().required('Please Enter Meeting Duration In Minutes.'),
    meetingPersons: Yup.array().min(1, 'At least one is selected!'),
    meetingMedium: Yup.string().required('Please Select Medium.'),
    date: Yup.date().required('Date Required!').nullable(),
    time: Yup.date().required('Time Required!').nullable(),
})

const UpdateMeetingForm = (props) => {
    const auth = useContext(AuthContext);
    const { userName, userLogin, userEmail } = useAuth();
    const formRef = useRef();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [optionsClientNames, setOptionClientNames] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState("");
    const [optionsProjects, setOptionsProjects] = useState([{ label: "Select", value: "" }]);
    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [optionsAssignments, setOptionsAssignments] = useState([{ label: "Select", value: "" }]);
    const [optionsEstimates, setOptionsEstimates] = useState([{ label: "Select", value: "" }]);
    const [optionsResourceNames, setOptionsResourceNames] = useState([]);
    const [serverErrorMessage, setServerErrorMessage ] = useState("");
    const resArray =Array.from(JSON.parse(props.updateMeeting.person_id));
    const [isFirstLoaded, setIsFirstLoaded ] = useState(true); 
    const [isEstimate, setIsEstimate ] = useState(false);
    const [dialogIsOpen, setIsOpen] = useState(false);

    const onDialogClose = (e) => {
        setIsOpen(false)
    }
    
    const handleEstimateOrAssignmentChange = (value, onChange = 0) => {              
        setIsEstimate(value);    
        if(onChange) 
        {
            setInitialValuesForm(prevInitialValuesForm => ({
                ...prevInitialValuesForm,
                assignmentName  : "",
                estimateName : "",
                estimateOrAssignment : value
            })); 
            
        }  
    };

    const initialValues = {
        clientName: props.updateMeeting.client_id.toString(),
        projectName: props.updateMeeting.project_id.toString(),
        assignmentName: props.updateMeeting.assignment_id ? props.updateMeeting.assignment_id.toString() : "" ,
        estimateName: props.updateMeeting.estimate_id ? props.updateMeeting.estimate_id.toString() : "",
        estimateOrAssignment: props.updateMeeting.is_estimate,
        meetingTitle: props.updateMeeting.meeting_title,
        meetingDuration: props.updateMeeting.meeting_duration,
        meetingPersons: [],
        meetingMedium: props.updateMeeting.meeting_medium,
        date: new Date(dateFormat(props.updateMeeting.meeting_date_time, "yyyy-mm-dd")),
        time: new Date(dateFormat(props.updateMeeting.meeting_date_time, "yyyy-mm-dd HH:MM")),
    };

    const [initialValuesForm, setInitialValuesForm] = useState(initialValues);   
    
    
    const handleClientNameChange = (clientId) => {    
        setSelectedClientId(clientId);
        const timer = setTimeout(() => {
            fetchProjectsBYClientId(clientId);
        }, 500);
        return () => clearTimeout(timer);
        
    };
    

    const handleProjectNameChange = (projectId, clientId=null) => { 
        //console.log(projectId);
        setSelectedProjectId(projectId);
        const timer = setTimeout(() => {
            fetchAssignmentsBYClientIdProjectId(projectId, clientId);
            fetchEstimatessBYClientIdProjectId(projectId, clientId);
        }, 500);
        return () => clearTimeout(timer);
        
    };  

    const fetchAssignmentsBYClientIdProjectId = async (projectId, clientId) => {
        //formRef.current.values.assignmentName=""; 
        let CID;   
        if(clientId) 
        {
            CID=clientId;
        }   
        else
        {
            CID=selectedClientId;
        }  
        setOptionsAssignments([{ label: "Select", value: "" }]); 
        if (projectId) {
            try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_ERP_URL}/getAssignments.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f&client_id=${CID}&project_id=${projectId}`
            );
            const getAssignmentsItmes = responseData.assignments;
            const options = [{ label: "Select", value: "" }];
            if(responseData.status==='success')
            {
                if (getAssignmentsItmes && getAssignmentsItmes.length > 0) {
                    getAssignmentsItmes.map((element) => {
                        options.push({ label: element.assignment_title, value: element.assignment_id });
                        return null;
                    
                    });
                    setOptionsAssignments(options);
                    if(isFirstLoaded)
                    {
                        setInitialValuesForm(prevInitialValuesForm => ({
                            ...prevInitialValuesForm,
                            assignmentName  : props.updateMeeting.assignment_id
                        }));                         
                    }
                    else
                    {
                        setInitialValuesForm(prevInitialValuesForm => ({
                            ...prevInitialValuesForm,
                            projectName: projectId,
                            assignmentName  : "",
                            estimateOrAssignment : 0
                        }));
                        setIsEstimate(false);
                    }
                    
                        
                    /*if(isFirstLoaded)
                    {
                        initialValues.assignmentName = props.updateMeeting.assignment_id;                        
                    }
                    else
                    {
                        initialValues.assignmentName = "";
                        initialValues.projectName = projectId;
                    }
                    setInitialValuesForm(initialValues);*/
                }
            }
            else
            {
                setOptionsAssignments([{ label: "Select", value: "" }]); 
                setInitialValuesForm(prevInitialValuesForm => ({
                    ...prevInitialValuesForm,
                    assignmentName  : ""
                }));
            }
            } catch (err) {}
        } 
       
    };  


    const fetchEstimatessBYClientIdProjectId = async (projectId, clientId) => {
         
        let CID;   
        if(clientId) 
        {
            CID=clientId;
        }   
        else
        {
            CID=selectedClientId;
        }  
        setOptionsEstimates([{ label: "Select", value: "" }]); 
        if (projectId) {
            try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_ERP_URL}/getEstimates.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f&client_id=${CID}&project_id=${projectId}`
            );
            const getEstimatesItmes = responseData.estimates;
            const options = [{ label: "Select", value: "" }];
            if(responseData.status==='success')
            {
                if (getEstimatesItmes && getEstimatesItmes.length > 0) {
                    getEstimatesItmes.map((element) => {
                        options.push({ label: element.estimate_title, value: element.estimate_id });
                        return null;
                    
                    });
                    setOptionsEstimates(Array.from(options));
                    // console.log(Array.from(optionsEstimates));
                    if(isFirstLoaded)
                    {
                        setInitialValuesForm(prevInitialValuesForm => ({
                            ...prevInitialValuesForm,
                            estimateName  : props.updateMeeting.estimate_id
                        }));                         
                    }
                    else
                    {
                        setInitialValuesForm(prevInitialValuesForm => ({
                            ...prevInitialValuesForm,
                            projectName: projectId,
                            estimateName  : ""
                        }));
                    }
                    
                    
                }
            }
            else
            {
                setOptionsEstimates([{ label: "Select", value: "" }]); 
                setInitialValuesForm(prevInitialValuesForm => ({
                    ...prevInitialValuesForm,
                    estimateName  : ""
                }));
            }
            } catch (err) {}
        } 
       
    };

    const fetchProjectsBYClientId = async (clientId) => {   
        setOptionsProjects([{ label: "Select", value: "" }]); 
        setOptionsAssignments([{ label: "Select", value: "" }]);
        if (clientId) {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_ERP_URL}/getProjects.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f&client_id=${clientId}`
                );
                const getProjectsItmes = responseData.projects;
                const options = [{ label: "Select", value: "" }];
                if(responseData.status==='success')
                {
                    if (getProjectsItmes && getProjectsItmes.length > 0) {
                        getProjectsItmes.map((element) => {
                            options.push({ label: element.project_title, value: element.project_id });
                            return null;
                        });
                        setOptionsProjects(options);  
                        // console.log(optionsProjects);
                        if(isFirstLoaded)
                        {
                            setInitialValuesForm(prevInitialValuesForm => ({
                                ...prevInitialValuesForm,
                                projectName: props.updateMeeting.project_id
                            }));                         
                        }
                        else
                        {
                            setInitialValuesForm(prevInitialValuesForm => ({
                                ...prevInitialValuesForm,
                                projectName: "",
                                clientName  : clientId
                            }));
                        }
                        
                        //console.log(initialValuesForm);
                        
                    }
                }
            
            } catch (err) {}
        } 
        
    };  

    useEffect(() => {
       
        const fetchClientNames = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_ERP_URL}/getClients.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f`
            );
            const options = [{ label: "Select", value: "" }];
            if(responseData.status==='success')
            {
                const fetchClientNames = responseData.clients;
                
                if (fetchClientNames.length > 0) {
                    fetchClientNames.map((element) => {
                    options.push({ label: element.client_name, value: element.client_id });
                    return null;
                });
                setOptionClientNames(options);
                }
            }
            
          } catch (err) {
            //console.log(err);
          }
          
        };
        fetchClientNames();

        const fetchResourceNames = async () => {
            try {
              const responseData = await sendRequest(
                `${process.env.REACT_APP_ERP_URL}/getResources.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f`
              );
              const options = [{ label: "Select", value: "" }];
              if(responseData.status==='success')
              {
                  const fetchResourceNames = responseData.resources;
                  
                  if (fetchResourceNames.length > 0) {
                        fetchResourceNames.map((element) => {
                            options.push({ value: element.resource_id, label: element.resource_name });
                            return null;
                        });
                  setOptionsResourceNames(options);
                  if (options.length > 0) {

                        const dataArray = options.filter((element) => {
                            let data = resArray.filter((val) => val==element.value);
                                if(data.length > 0 && element.label!=='Select')
                                {
                                    return { value: element.value, label: element.label };
                                } 
                                
                        });
                        if(isFirstLoaded)
                        {
                            //initialValues.meetingPersons = dataArray;
                            //setInitialValuesForm(initialValues);
                            setInitialValuesForm(prevInitialValuesForm => ({
                                ...prevInitialValuesForm,
                                meetingPersons: dataArray                                
                            })); 
                        }                       
                        
                    }
                  
                  }
              }
              
            } catch (err) {
              //console.log(err);
            }
          };
        fetchResourceNames();
          
        handleClientNameChange(props.updateMeeting.client_id);
        handleProjectNameChange(props.updateMeeting.project_id, props.updateMeeting.client_id);
        handleEstimateOrAssignmentChange(props.updateMeeting.is_estimate);
        
        setIsFirstLoaded(false);
    //   console.log(initialValues.meetingPersons);
        //console.log(initialValues);
      }, [sendRequest, auth.userId]);
    
          
    return (
        <div>
            {(serverErrorMessage) && (
                <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <Alert className="mb-4" type="danger" showIcon>
                    {serverErrorMessage}
                </Alert>
            </Dialog>
                
            )}
            
            {isLoading && (
                <div className="center flex items-center">                
                    <Spinner size="3.25rem" />
                </div>
            )}

            {!isLoading && (
            <Formik
                enableReinitialize={true}
                initialValues={initialValuesForm}
                validationSchema={validationSchema}
                onSubmit={ async (values, { setSubmitting }) => {
                    //console.log('values', values)

                    const selectedClientname = optionsClientNames.filter(cname => cname.value === values.clientName);
                    const selectedProject = optionsProjects.filter(pname => pname.value === values.projectName);
                    const selectedAssignment = optionsAssignments.filter(aname => aname.value === values.assignmentName);
                    const selectedEstimate = optionsEstimates.filter(ename => ename.value === values.estimateName);
                    const personIdArray= [];
                    const personNameArray = [];
                    const CurrentDate = new Date();
                    values.meetingPersons.map((element) => {
                        personIdArray.push(element.value);
                        personNameArray.push(element.label);
                    
                    });
                    const method = 'PUT';
                    const body = JSON.stringify({
                        client_id: values.clientName,
                        client_name: selectedClientname[0].label,
                        project_id: values.projectName,
                        project_title: selectedProject[0].label,
                        assignment_id: values.assignmentName ? values.assignmentName : 0,
                        assignment_title: selectedAssignment.length ? selectedAssignment[0].label : "" ,
                        is_estimate: values.estimateOrAssignment,
                        estimate_id: values.estimateName ? values.estimateName : 0,
                        estimate_title: selectedEstimate.length ? selectedEstimate[0].label : "",
                        meeting_title: values.meetingTitle,
                        meeting_duration: values.meetingDuration,
                        person_id: personIdArray,
                        person_name: personNameArray,
                        meeting_medium: values.meetingMedium,
                        meeting_date_time: dateFormat(values.date, "yyyy-mm-dd")+" "+dateFormat(values.time, "HH:MM"),
                        created_by: userName,
                        created_by_date_time: dateFormat(CurrentDate, "yyyy-mm-dd HH:MM"),
                        updated_by: userName,
                        updated_by_date_time: dateFormat(CurrentDate, "yyyy-mm-dd HH:MM"),
                        });
                    const headers = {
                        
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + auth.token,
                        
                    };
                    //console.log(dateFormat(values.date, "yyyy-mm-dd")+" "+dateFormat(values.time, "HH:MM"));
                    //console.log(body);
                    try {
                        
                            const responseData = await sendRequest(
                                `${process.env.REACT_APP_BACKEND_URL}/meetings/${props.updateMeeting.id}`,
                                method,
                                body,
                                headers,
                            );
                                //console.log(responseData);
                            if(responseData.success)
                            {
                                setSubmitting(false);
                                props.onUpdateDrawerClose();
                                props.fetchMeetings(props.currentPage);
                                props.showMeetingUpdateAlert();
                            }
                            else
                            {
                                setInitialValuesForm(values);
                                setServerErrorMessage(responseData.message);
                                setIsOpen(true);
                            }
                      } catch (err) {
                            console.log(err);
                      }
                    
                }}
                innerRef={formRef}
            >
                {({ values, touched, errors, resetForm, handleChange }) => (
                    <Form id='meeting-form'>
                        <FormContainer>
                            <FormItem
                                label="Client Name"
                                asterisk
                                invalid={errors.clientName && touched.clientName}
                                errorMessage={errors.clientName}
                            >
                                <Field name="clientName">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={optionsClientNames}
                                            value={optionsClientNames.filter(
                                                (option) => {
                                                    //console.log(option.value+" || "+values.clientName);
                                                    return  option.value === values.clientName
                                                }
                                                    
                                            )}
                                            onChange={(option) => {
                                                    handleClientNameChange(option.value);
                                                    //console.log(option.value+" || "+values.projectName);
                                                    form.setFieldValue(
                                                        field.name,
                                                        option.value
                                                    );
                                                   // handleChange('clientName');
                                                }
                                                
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>

                            <FormItem
                                label="Project Name"
                                asterisk
                                invalid={errors.projectName && touched.projectName}
                                errorMessage={errors.projectName}
                            >
                                <Field name="projectName">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={optionsProjects}
                                            value={optionsProjects.filter(
                                                (option) => {
                                                   return  option.value == values.projectName
                                                }
                                                    
                                            )}
                                            onChange={(option) => {
                                                    handleProjectNameChange(option.value);
                                                    form.setFieldValue(
                                                        field.name,
                                                        option.value
                                                    )
                                                    
                                                }
                                                
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            
                            <FormItem
                                label="Assignment OR Estimate"
                                asterisk
                                invalid={errors.estimateOrAssignment && touched.estimateOrAssignment}
                                errorMessage={errors.estimateOrAssignment}
                            >
                                <Field name="estimateOrAssignment">
                                    {({ field, form }) => (
                                        <Radio.Group
                                            value={values.estimateOrAssignment}
                                            onChange={(val) => {
                                                    form.setFieldValue(
                                                        field.name,
                                                        val
                                                    );
                                                    handleEstimateOrAssignmentChange(val, 1);
                                                }
                                            }
                                        >
                                            <Radio value={0}>Assignment</Radio>
                                            <Radio value={1}>Estimate</Radio>
                                            
                                        </Radio.Group>
                                    )}
                                </Field>
                            </FormItem>
                            {isEstimate ? (
                            <FormItem
                                label="Estimate Name"
                                asterisk
                                invalid={errors.estimateName && touched.estimateName}
                                errorMessage={errors.estimateName}
                            >
                                <Field name="estimateName">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={optionsEstimates}
                                            value={optionsEstimates.filter(
                                                (option) =>
                                                    option.value == values.estimateName
                                            )}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            ) : (

                                <FormItem
                                label="Assignment Name"
                                asterisk
                                invalid={errors.assignmentName && touched.assignmentName}
                                errorMessage={errors.assignmentName}
                            >
                                <Field name="assignmentName">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={optionsAssignments}
                                            value={optionsAssignments.filter(
                                                (option) =>{
                                                    return option.value == values.assignmentName
                                                }
                                            )}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            )}

                            <FormItem
                                label="Meeting Objective / Title"
                                asterisk
                                invalid={errors.meetingTitle && touched.meetingTitle}
                                errorMessage={errors.meetingTitle}
                            >
                                <Field
                                    type="text"
                                    name="meetingTitle"
                                    placeholder="Meeting Objective / Title"
                                    component={Input}
                                />
                            </FormItem>

                            <FormItem
                                label="Meeting Duration in minutes"
                                asterisk
                                invalid={errors.meetingDuration && touched.meetingDuration}
                                errorMessage={errors.meetingDuration}
                            >
                                <Field
                                    type="number"
                                    name="meetingDuration"
                                    placeholder="Meeting Duration in minutes"
                                    component={Input}
                                />
                            </FormItem>

                            <FormItem
                                label="Meeting person"
                                asterisk
                                invalid={Boolean(
                                    errors.meetingPersons &&
                                        touched.meetingPersons
                                )}
                                errorMessage={errors.meetingPersons}
                            >
                                <Field name="meetingPersons">
                                    {({ field, form }) => (
                                        <Select
                                            componentAs={CreatableSelect}
                                            isMulti
                                            field={field}
                                            form={form}
                                            defaultValue={initialValuesForm.meetingPersons}
                                            options={optionsResourceNames}
                                            value={values.optionsResourceNames}
                                            onChange={(option) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    option
                                                )
                                            }}
                                        />
                                    )}
                                </Field>
                            </FormItem>

                            <FormItem
                                label="Medium"
                                asterisk
                                invalid={errors.meetingMedium && touched.meetingMedium}
                                errorMessage={errors.meetingMedium}
                            >
                                <Field name="meetingMedium">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={MeetingMedium}
                                            value={MeetingMedium.filter(
                                                (option) =>{
                                                    return option.value == values.meetingMedium.toLowerCase();
                                                }
                                            )}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>

                            <FormItem
                                label="Meeting Date"
                                asterisk
                                invalid={errors.date && touched.date}
                                errorMessage={errors.date}
                            >
                                <Field name="date" placeholder="Pick a date">
                                    {({ field, form }) => (
                                        <DatePicker
                                            field={field}
                                            form={form}
                                            value={field.value}
                                            onChange={(date) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    date
                                                )
                                            }}
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            <FormItem
                                label="Meeting Time"
                                asterisk
                                invalid={errors.time && touched.time}
                                errorMessage={errors.time}
                            >
                                <Field name="time" placeholder="Time">
                                    {({ field, form }) => (
                                        <TimeInput
                                            format="12"
                                            field={field}
                                            form={form}
                                            value={field.value}
                                            onChange={(time) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    time
                                                )
                                            }}
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            
                        </FormContainer>
                    </Form>
                )}
            </Formik>
            )}
        </div>
    )
}

export default UpdateMeetingForm

