import { FormContainer, FormItem, Input, Button, DatePicker, Select, Spinner, Alert, Card, Radio  } from 'components/ui'
import React, { useContext, useState, useEffect, useRef } from 'react';

import { Field, Form, Formik } from 'formik'
import CreatableSelect from 'react-select/creatable'
import * as Yup from 'yup'
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import dateFormat from 'dateformat';


const validationSchema = Yup.object().shape({
    
  participantName: Yup.string().required('Please Select Participant Name.'),
 
})

const { DatePickerRange } = DatePicker

const ReportForm = (props) => {
    const auth = useContext(AuthContext);
    const formRef = useRef();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [optionsClientNames, setOptionClientNames] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState("");
    const [optionsProjects, setOptionsProjects] = useState([{ label: "Select", value: "" }]);
    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [optionsAssignments, setOptionsAssignments] = useState([{ label: "Select", value: "" }]);
    const [optionsEstimates, setOptionsEstimates] = useState([{ label: "Select", value: "" }]);
    const [optionsResourceNames, setOptionsResourceNames] = useState([]);
    const [ serverErrorMessage, setServerErrorMessage ] = useState("");
    const [isEstimate, setIsEstimate ] = useState(false);

    const handleEstimateOrAssignmentChange = (value) => {        
        setIsEstimate(value);        
    };

    const handleClientNameChange = (clientId) => {        
        setSelectedClientId(clientId);
        fetchProjectsBYClientId(clientId);
    };

    const handleProjectNameChange = (projectId) => {        
        setSelectedProjectId(projectId);
        fetchAssignmentsBYClientIdProjectId(projectId);
        fetchEstimatessBYClientIdProjectId(projectId);
    };
    
    const fetchAssignmentsBYClientIdProjectId = async (projectId) => {
        formRef.current.values.assignmentName="";   
        setOptionsAssignments([{ label: "Select", value: "" }]); 
        if (projectId) {
            try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_ERP_URL}/getAssignments.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f&client_id=${selectedClientId}&project_id=${projectId}`
            );
            const getAssignmentsItmes = responseData.assignments;
            const options = [{ label: "Select", value: "" }];
            if(responseData.status==='success')
            {
                if (getAssignmentsItmes && getAssignmentsItmes.length > 0) {
                    getAssignmentsItmes.map((element) => {
                        options.push({ label: element.assignment_title, value: element.assignment_id });
                        return null;
                    
                    });
                    setOptionsAssignments(options);
                    
                }
            }
            else
            {
                setOptionsAssignments([{ label: "Select", value: "" }]); 
            }
            } catch (err) {}
        } 
    };  

    const fetchEstimatessBYClientIdProjectId = async (projectId) => {
        formRef.current.values.estimateName="";   
        setOptionsEstimates([{ label: "Select", value: "" }]); 
        if (projectId) {
            try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_ERP_URL}/getEstimates.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f&client_id=${selectedClientId}&project_id=${projectId}`
            );
            const getEstimatesItmes = responseData.estimates;
            const options = [{ label: "Select", value: "" }];
            if(responseData.status==='success')
            {
                if (getEstimatesItmes && getEstimatesItmes.length > 0) {
                    getEstimatesItmes.map((element) => {
                        options.push({ label: element.estimate_title, value: element.estimate_id });
                        return null;
                    
                    });
                    setOptionsEstimates(options);
                    
                }
            }
            else
            {
                setOptionsEstimates([{ label: "Select", value: "" }]); 
            }
            } catch (err) {}
        } 
    };  

    const fetchProjectsBYClientId = async (clientId) => {
        formRef.current.values.projectName="";   
        formRef.current.values.assignmentName=""; 
        setOptionsProjects([{ label: "Select", value: "" }]); 
        setOptionsAssignments([{ label: "Select", value: "" }]);
        if (clientId) {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_ERP_URL}/getProjects.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f&client_id=${clientId}`
                );
                const getProjectsItmes = responseData.projects;
                const options = [{ label: "Select", value: "" }];
                if(responseData.status==='success')
                {
                    if (getProjectsItmes && getProjectsItmes.length > 0) {
                        getProjectsItmes.map((element) => {
                            options.push({ label: element.project_title, value: element.project_id });
                            return null;
                        
                        });
                        setOptionsProjects(options);
                        
                    }
                }
            
            } catch (err) {}
        } 
    };  

    useEffect(() => {
      const fetchClientNames = async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_ERP_URL}/getClients.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f`
          );
          const options = [{ label: "Select", value: "" }];
          if(responseData.status==='success')
          {
              const fetchClientNames = responseData.clients;
              
              if (fetchClientNames.length > 0) {
                  fetchClientNames.map((element) => {
                  options.push({ label: element.client_name, value: element.client_id });
                  return null;
              });
              setOptionClientNames(options);
              }
          }
          
        } catch (err) {
          //console.log(err);
        }
      };
      fetchClientNames();

      const fetchResourceNames = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_ERP_URL}/getResources.php?token=AxOCwiaWF0IjoxNjgwMjQzMDE4f`
            );
            const options = [{ label: "Select", value: "" }];
            if(responseData.status==='success')
            {
                const fetchResourceNames = responseData.resources;
                
                if (fetchResourceNames.length > 0) {
                  fetchResourceNames.map((element) => {
                    options.push({ label: element.resource_name, value: element.resource_id });
                    return null;
                });
                setOptionsResourceNames(options);
                }
            }
            
          } catch (err) {
            //console.log(err);
          }
        };
      fetchResourceNames();
    }, [sendRequest, auth.userId]);
    
  return (
    <>
    <div className="page-container relative h-full flex flex-auto flex-col">
            <div className="pt-8 pb-4 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800">
              <div className="container mx-auto px-6">
                  <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-4">
                      <div className="">
                          <h3 className='text-3xl'>Meeting Report</h3>
                      </div>
                      
                  </div>
              </div>
            </div>
    
            {/*  */}
    
            <div className="container mx-auto h-full px-6 py-6">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <div className="lg:col-span-2">
    <Card>
      <div>
          {isLoading && (
              <div className="center flex items-center">                
                  <Spinner size="3.25rem" />
              </div>
          )}
          {(serverErrorMessage) && (
              <Alert className="mb-4" type="danger" showIcon>
                  {serverErrorMessage}
              </Alert>
          )}
          <Formik
              enableReinitialize
              initialValues={{
                  participantName: '',
                  clientName: '',
                  projectName: '',
                  estimateOrAssignment: 0,
                  estimateName: '',
                  assignmentName: '',                
                  date: null,                
              }}
              validationSchema={validationSchema}
              onSubmit={ async (values, { setSubmitting }) => {
                  //console.log('values', values)
                  try {
                    setSubmitting(false); 
                    props.handleFormData(values);
                  }
                  catch (err) {
                    console.log(err);
                  }
                  
              }}
              innerRef={formRef}
          >
              {({ values, touched, errors, resetForm }) => (
                  <Form id='report-form'>
                      <FormContainer>
                      <FormItem
                              label="Participant"
                              asterisk
                              invalid={errors.participantName && touched.participantName}
                              errorMessage={errors.participantName}
                          >
                              <Field name="participantName">
                                  {({ field, form }) => (
                                      <Select
                                          className="min-w-[120px]"
                                          field={field}
                                          form={form}
                                          options={optionsResourceNames}
                                          value={optionsResourceNames.filter(
                                              (option) =>
                                                  option.value ===
                                                  values.participantName
                                          )}
                                          onChange={(option) => {
                                                  form.setFieldValue(
                                                      field.name,
                                                      option.value
                                                  );
                                                  
                                              }
                                              
                                          }
                                      />
                                  )}
                              </Field>
                          </FormItem>

                          <FormItem
                              label="Client Name"
                              
                              invalid={errors.clientName && touched.clientName}
                              errorMessage={errors.clientName}
                          >
                              <Field name="clientName">
                                  {({ field, form }) => (
                                      <Select
                                          className="min-w-[120px]"
                                          field={field}
                                          form={form}
                                          options={optionsClientNames}
                                          value={optionsClientNames.filter(
                                              (option) =>
                                                  option.value ===
                                                  values.clientName
                                          )}
                                          onChange={(option) => {
                                                  form.setFieldValue(
                                                      field.name,
                                                      option.value
                                                  );
                                                  handleClientNameChange(option.value);
                                              }
                                              
                                          }
                                      />
                                  )}
                              </Field>
                          </FormItem>

                          <FormItem
                              label="Project"
                              
                              invalid={errors.projectName && touched.projectName}
                              errorMessage={errors.projectName}
                          >
                              <Field name="projectName">
                                  {({ field, form }) => (
                                      <Select
                                          className="min-w-[120px]"
                                          field={field}
                                          form={form}
                                          options={optionsProjects}
                                          value={optionsProjects.filter(
                                              (option) =>
                                                  option.value ===
                                                  values.projectName
                                          )}
                                          onChange={(option) => {
                                                  form.setFieldValue(
                                                      field.name,
                                                      option.value
                                                  )
                                                  handleProjectNameChange(option.value);
                                              }
                                              
                                          }
                                      />
                                  )}
                              </Field>
                          </FormItem>
                          

                          <FormItem
                                label="Assignment OR Estimate"
                                asterisk
                                invalid={errors.estimateOrAssignment && touched.estimateOrAssignment}
                                errorMessage={errors.estimateOrAssignment}
                            >
                                <Field name="estimateOrAssignment">
                                    {({ field, form }) => (
                                        <Radio.Group
                                            value={values.estimateOrAssignment}
                                            onChange={(val) => {
                                                    form.setFieldValue(
                                                        field.name,
                                                        val
                                                    );
                                                    handleEstimateOrAssignmentChange(val);
                                                }
                                            }
                                        >
                                            <Radio value={0}>Assignment</Radio>
                                            <Radio value={1}>Estimate</Radio>
                                            
                                        </Radio.Group>
                                    )}
                                </Field>
                            </FormItem>
                            {isEstimate ? (
                            <FormItem
                                label="Estimate"
                                asterisk
                                invalid={errors.estimateName && touched.estimateName}
                                errorMessage={errors.estimateName}
                            >
                                <Field name="estimateName">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={optionsEstimates}
                                            value={optionsEstimates.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.estimateName
                                            )}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            ) : (

                                <FormItem
                                label="Assignment"
                                
                                invalid={errors.assignmentName && touched.assignmentName}
                                errorMessage={errors.assignmentName}
                            >
                                <Field name="assignmentName">
                                    {({ field, form }) => (
                                        <Select
                                            className="min-w-[120px]"
                                            field={field}
                                            form={form}
                                            options={optionsAssignments}
                                            value={optionsAssignments.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.assignmentName
                                            )}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            )}

                          

                          <FormItem
                              label="Date"                            
                              invalid={errors.date && touched.date}
                              errorMessage={errors.date}
                          >
                              <Field name="date" placeholder="Date From Date To">
                                  {({ field, form }) => (
                                      <DatePicker.DatePickerRange
                                          field={field}
                                          form={form}
                                          dateViewCount={2}
                                          onChange={(date) => {
                                              form.setFieldValue(
                                                  field.name,
                                                  date
                                              )
                                          }}
                                      />

                                  )}
                              </Field>
                          </FormItem>                       
                          <FormItem>                                
                              <Button variant="solid" type="submit">
                                  Submit
                              </Button>
                          </FormItem>
                      </FormContainer>
                  </Form>
              )}
          </Formik>
      </div>
    </Card>  
    </div>
          <div className="lg:col-span-1"></div>
        </div>

        </div>

        {/* <div className="container mx-auto h-full px-6 py-6">

        <Card>
        sdafasfs
        </Card>

        </div> */}
      </div>
    </>
  )
}

export default ReportForm

