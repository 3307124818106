import { Card, Drawer, Button, Pagination, Spinner, Alert, Dialog } from 'components/ui'
import React, { useState, useEffect, useContext } from 'react'

import {HiOutlinePencilAlt, HiTrash } from 'react-icons/hi'
import { Link, useParams, useLocation } from 'react-router-dom';
import dateFormat from 'dateformat';

import NewMeetingForm from './NewMeetingForm';
import UpdateMeetingForm from './UpdateMeetingForm';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import noUserImage from '../../assets/images/avatars/no-image.png';



const Meeting  = ({cardBorder}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const auth = useContext(AuthContext);
  const [loadedMeetings, setLoadedMeetings] = useState();
  const [isEmptyMeetings, setIsEmptyMeetings] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [totalHoursMinutes, setTotalHoursMinutes] = useState('0h 0m');
  const [updateMeeting, setUpdateMeeting] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteMeetinDialogIsOpen, setDeleteMeetinDialogIsOpen] = useState(false);
  const [deleteMeeting, setDeleteMeeting] = useState(null);
  const searchKeyword = useParams().keyword;
  const location = useLocation();

  const openDeleteMeetingDialog = (meeting) => {
      setDeleteMeetinDialogIsOpen(true);
      setDeleteMeeting(meeting);
  }

  const onDeleteMeetingDialogClose = (e) => {
      //console.log('onDialogClose', e)
      setDeleteMeetinDialogIsOpen(false);
  }

  const onDeleteMeetingDialogOk = (e) => {
      //console.log('onDialogOk', e)
      setDeleteMeetinDialogIsOpen(false);
      deleteMeetingFunction(deleteMeeting);
  }

  const deleteMeetingFunction = async (meeting) => {
    
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/meetings/${meeting.id}`,
        'DELETE',
        null,
        {
          Authorization: 'Bearer ' + auth.token
        }
      );
      if(responseData.success)
      {
          fetchMeetings(1);
          setSuccessMessage('Meeting deleted successfully.'); 
      }
      else
      {
        setErrorMessage('Error in delete meeting.')
      }
      setDeleteMeeting(null);
    } catch (err) {
      //console.log(err);
      setErrorMessage('Error in delete meeting.')
    }
  };

  const fetchMeetings = async (page) => {
    let ajaxUrl=`${process.env.REACT_APP_BACKEND_URL}/meetings?page=${page}`;
    if(searchKeyword)
    {
      ajaxUrl=`${process.env.REACT_APP_BACKEND_URL}/meetings?page=${page}&search=${searchKeyword}`;
    }
    
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const responseData = await sendRequest(
        ajaxUrl,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token
        }
      );
      console.log(responseData.data.response);
      setLoadedMeetings(responseData.data.response);
      if(responseData.data.response.data.length > 0)
      {
        setIsEmptyMeetings(false);
        setTotalHoursMinutes(responseData.data.total_hours)
      }
      else
      {
        setIsEmptyMeetings(true);
      }
    } catch (err) {}
  };

  useEffect(() => { 
    if(!deleteMeetinDialogIsOpen && !isUpdateOpen)
    {
      if(location.pathname.includes('/meetings/'))
      {
        fetchMeetings(1);
      } 
      else
      {
        if(loadedMeetings)
        {
          fetchMeetings(loadedMeetings.current_page);
        }
        else
        {
          fetchMeetings(1);
        }
      } 
    }
    
    
  }, [sendRequest, auth.userId, location]);

  const onPaginationChange = (page) => {
      //console.log('onPaginationChange', page)
      fetchMeetings(page);
  }

  //console.log(loadedMeetings);
  
	const openDrawer = () => {
		setIsOpen(true)
	}

  const showMeetingUpdateAlert = () => {
		setSuccessMessage('Meeting updated successfully.')
	}

  const showMeetingAddedAlert = () => {
		setSuccessMessage('Meeting added successfully.')
	}

	const onDrawerClose = e => {
		//console.log('onDrawerClose', e)
		setIsOpen(false)
	}

  const openUpdateDrawer = (meeting) => {
		setIsUpdateOpen(true);
    setUpdateMeeting(meeting);
	}

	const onUpdateDrawerClose = e => {
		//console.log('onDrawerClose', e)
		setIsUpdateOpen(false)
	}

  const Footer = (
    <div className="flex w-full items-start">
        <Button className="mx-2" type="submit" form="meeting-form" variant="solid" block >Save</Button>
        <Button className="mx-2" block onClick={() => onDrawerClose()}>Cancel</Button>
    </div>
  )

  const FooterUpdate = (
    <div className="flex w-full items-start">
        <Button className="mx-2" type="submit" form="meeting-form" variant="solid" block >Update</Button>
        <Button className="mx-2" block onClick={() => onUpdateDrawerClose()}>Cancel</Button>
    </div>
  )



 
  return (
    <>
      <div className="page-container relative h-full flex flex-auto flex-col">
        <div className="pt-8 pb-4 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-6">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-4">
                  <div className="">
                      <h3 className='text-3xl'>Meetings</h3>
                      { !isLoading && loadedMeetings && (
                      <span className='block text-xs mt-1 text-gray-400'>{loadedMeetings.total} Meetings of {totalHoursMinutes} </span>
                      )}
                  </div>
                  <div className="flex items-center gap-2">
                      <div className="">
                      <Button onClick={() => openDrawer()}>Add New Meeting</Button>
                      <Drawer
                        title='Add new meeting'
                        isOpen={isOpen}
                        onClose={onDrawerClose}
                        onRequestClose={onDrawerClose}
                        footer={Footer}
                        headerclassName="!items-start !bg-gray-100 dark:!bg-gray-700"
                        footerclassName="!border-t-0 !p-3"
                        >
                          <NewMeetingForm showMeetingAddedAlert={showMeetingAddedAlert} onDrawerClose={onDrawerClose} fetchMeetings={fetchMeetings} />
                        </Drawer>
                        { !isLoading && loadedMeetings && (
                        <Drawer
                        title='Update Meeting'
                        isOpen={isUpdateOpen}
                        onClose={onUpdateDrawerClose}
                        onRequestClose={onUpdateDrawerClose}
                        footer={FooterUpdate}                        
                        headerclassName="!items-start !bg-gray-100 dark:!bg-gray-700"
                        footerclassName="!border-t-0 !p-3"
                        >
                          <UpdateMeetingForm showMeetingUpdateAlert={showMeetingUpdateAlert} updateMeeting={updateMeeting} onUpdateDrawerClose={onUpdateDrawerClose} fetchMeetings={fetchMeetings} currentPage={loadedMeetings.current_page} />
                        </Drawer>
                        )}

                        { !isLoading && loadedMeetings && (  
                          <Dialog
                              isOpen={deleteMeetinDialogIsOpen}
                              onClose={onDeleteMeetingDialogClose}
                              onRequestClose={onDeleteMeetingDialogClose}
                          >
                          <h5 className="mb-4">Are you sure about delete</h5>
                          
                          <div className="text-right mt-6">
                              <Button variant="solid" onClick={onDeleteMeetingDialogOk}>
                                  Yes
                              </Button>
                              <Button
                                  className="ltr:mr-2 rtl:ml-2"
                                  variant="plain"
                                  onClick={onDeleteMeetingDialogClose}
                              >
                                  Cancel
                              </Button>
                              
                          </div>
                      </Dialog>
                      )}
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <div className="container mx-auto h-full px-6">
          <div className="mt-6 h-full flex flex-col">
          {isLoading && (
            <div className="center flex items-center">                
                <Spinner size="3.25rem" />
            </div>
          )} 

          {error && (
            <div>
              <Alert type="danger" closable  >
                  {error}
              </Alert>
          </div>
          )} 
          {errorMessage && (
            <div>
                <Alert type="danger" closable  >
                    {errorMessage}
                </Alert>
            </div>
          )} 
          {isEmptyMeetings && (
            <div>
                <Alert type="danger" closable  >
                No meeting found
                </Alert>
            </div>
          )} 

        {successMessage && (    
          <div>
              <Alert type="success" closable  >
                  {successMessage}
              </Alert>
          </div>
        )}
        
          { !isLoading && loadedMeetings && loadedMeetings.data.map((meeting, index) => (  
              <div className="mb-4" data-index={index} key={index}>
                <Card bordered={cardBorder}>
                    <div className='grid gap-x-4 grid-cols-12'>
                        {/* <div className="my-1 sm:my-0 col-span-12 sm:col-span-2 md:col-span-3 lg:col-span-3 md:flex md:items-center"> */}
                        <div className="col-span-12 lg:col-span-3 lg:flex lg:items-center">
                          <div className="flex flex-col">
                              <h6 className='font-bold'>{meeting.project_title}</h6>  
                              Client Name: {meeting.client_name}  <br />
                              Duration : {meeting.meeting_duration} minutes <br />
                              {meeting.meeting_date_time && (
                                <div>
                                Meeting Time: {dateFormat(meeting.meeting_date_time, "dddd, mmmm d, yyyy, hh:MM TT")} <br />
                                </div>
                              )}
                              Mode: {meeting.meeting_medium} <br />
                              {meeting.created_by && (
                                <div>
                                Created By: {meeting.created_by} <br />
                                </div>
                              )}
                              {meeting.created_by_date_time && (
                                <div>
                                Created Date: {dateFormat(meeting.created_by_date_time, "dddd, mmmm d, yyyy, hh:MM TT")} <br />
                                </div>
                              )}

                              {meeting.updated_by && (
                                <div>
                                Update By: {meeting.updated_by} <br />
                                </div>
                              )}
                              {meeting.updated_by_date_time && (
                                <div>
                                Updated Date: {dateFormat(meeting.updated_by_date_time, "dddd, mmmm d, yyyy, hh:MM TT")} <br />
                                </div>
                              )}

                             
                          </div>
                          </div>

                          {/* <div className="my-1 sm:my-0 col-span-12 sm:col-span-2 md:col-span-2 lg:col-span-2 md:flex md:items-center"> */}
                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-2 md:flex md:items-center">
                          {meeting.meeting_title}
                          </div>

                          {/* <div className="my-1 sm:my-0 col-span-12 md:col-span-2 lg:col-span-3 md:flex md:items-center"> */}
                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-3 md:flex md:items-center">
                          {dateFormat(meeting.meeting_date_time, "dddd, mmmm d, yyyy") }
                          </div>

                          {/* <div className="my-1 sm:my-0 col-span-12 md:col-span-3 lg:col-span-3 md:flex md:items-center"> */}
                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-2 md:flex md:items-center">
                              <div className="avatar-group avatar-group-chained">
                                { meeting.person_images && Object.entries(JSON.parse(meeting.person_images)).map(([key, value]) => (
                                    <div className="tooltip-wrapper " >
                                      <div className="avatar avatar-circle cursor-pointer" style={{width: "30px", height: "30px"}}>
                                      {value ? ( 
                                        <img src={value} alt="" className='avatar-img avatar-circle' />
                                        ) : ( 
                                          <img src={noUserImage} alt="" className='avatar-img avatar-circle' />
                                        )}  
                                      </div>
                                      
                                  </div>
                                  ))
                                }
                                
                                
                              </div>
                          </div>

                          <div className="flex lg:justify-end text-lg  lg:items-center">
                            <Link to="#" onClick={() => openUpdateDrawer(meeting)} className='cursor-pointer p-2 hover:text-blue-600'>
                              <HiOutlinePencilAlt/>
                            </Link>
                            <Link to="#" onClick={() => openDeleteMeetingDialog(meeting)} className='cursor-pointer p-2 hover:text-blue-600'>
                              <HiTrash/>
                            </Link>
                          </div>
                    </div>
                    
                </Card>
              </div>

            )
          )}  
          { !isLoading && !isEmptyMeetings   && loadedMeetings && loadedMeetings.last_page > 1 && (
            <div>              
              <Pagination pageSize={loadedMeetings.per_page} currentPage={loadedMeetings.current_page} total={loadedMeetings.total} onChange={onPaginationChange} />
            </div>
            
          )}
            
          </div>
          
        </div>
      </div>
    </>
  )
}

export default Meeting