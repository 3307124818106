import { Button, Card, Input, Pagination, Spinner, Alert, Dialog } from 'components/ui'
import React, { useState, useEffect, useContext } from 'react'
import { HiDownload, HiOutlineSearch } from 'react-icons/hi'
import { useParams, useLocation } from 'react-router-dom';
import dateFormat from 'dateformat';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import noUserImage from '../../assets/images/avatars/no-image.png';
import ExportExcel from '../../components/shared/Excelexport';


const ReportList = (props) => {
  const { state } = useLocation();
  const auth = useContext(AuthContext);
  const [loadedMeetings, setLoadedMeetings] = useState();
  const [isEmptyMeetings, setIsEmptyMeetings] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [totalHoursMinutes, setTotalHoursMinutes] = useState('0h 0m');
  const [participantNameSate, setParticipantNameSate] = useState('No Found');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [filteredMeetings, setFilteredMeetings] = useState([]);

  const fetchMeetings = async (page, state=null) => {
    //console.log(state);
    let ajaxUrl=`${process.env.REACT_APP_BACKEND_URL}/meetings?page=${page}&participantId=${state.participantName}&clientId=${state.clientName}&projectId=${state.projectName}&assignmentId=${state.assignmentName}&isEstimate=${state.estimateOrAssignment}&estimateId=${state.estimateName}`;
    if(state.date && state.date.length == 2 )
    {
      ajaxUrl=`${process.env.REACT_APP_BACKEND_URL}/meetings?page=${page}&participantId=${state.participantName}&clientId=${state.clientName}&projectId=${state.projectName}&assignmentId=${state.assignmentName}&isEstimate=${state.estimateOrAssignment}&estimateId=${state.estimateName}&fromDate=${dateFormat(state.date[0], "yyyy-mm-dd")}&toDate=${dateFormat(state.date[1], "yyyy-mm-dd")}`;
    }
       
        
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const responseData = await sendRequest(
        ajaxUrl,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token
        }
      );
      setLoadedMeetings(responseData.data.response);
      if(responseData.data.response.data.length > 0)
      {
        setIsEmptyMeetings(false);
        setTotalHoursMinutes(responseData.data.total_hours);
        setParticipantNameSate(responseData.data.participantName)
        const getAllFilteredMeetings = responseData.data.allFilterdMeetings;
        const dataMeetings = [];
        
        if(getAllFilteredMeetings.length > 0)
        {      
            
          getAllFilteredMeetings.map((element) => {
            let EstimateAssignMentTitle = "";
            if (element.is_estimate && element.estimate_title !== '') {
              EstimateAssignMentTitle="Estimate: "+element.estimate_title;
            }
            else if(!element.is_estimate && element.assignment_title !== '')
            {
              EstimateAssignMentTitle="Assignment: "+element.assignment_title;
              
            }  
            dataMeetings.push({ "Client": element.client_name, "Project": element.project_title, "Assignment/Estimate": EstimateAssignMentTitle, "Meeting Objective / Title": element.meeting_title, "Meeting Duration in minutes": element.meeting_duration+" minutes", "Meeting person": JSON.parse(element.person_name).map((name) =>{ return name.slice(0,1).toUpperCase() + name.slice(1, name.length)}).join(', '), "Medium": element.meeting_medium.slice(0,1).toUpperCase() + element.meeting_medium.slice(1, element.meeting_medium.length), "Meeting date and time": dateFormat(element.meeting_date_time, "dddd, mmmm d, yyyy HH:MM TT") });
              return null;
          
          });
          setFilteredMeetings(dataMeetings);
        }
      }
      else
      {
        setIsEmptyMeetings(true);
      }
    } catch (err) {}
  };

  const onPaginationChange = (page) => {
    //console.log('onPaginationChange', page)
    fetchMeetings(page, state);
}

  useEffect(() => { 
    if(loadedMeetings)
      {
        fetchMeetings(loadedMeetings.current_page, state);
      }
      else
      {
        fetchMeetings(1, state);
      }
    
    
  }, [sendRequest, auth.userId]);
  
  function AssignmentEstimateTitle(props) {
    if (props.meeting.is_estimate && props.meeting.estimate_title !== '') {
      return (
        <td className="py-4 px-6">Estimate : {props.meeting.estimate_title}</td>
      ) 
    }
    else if(!props.meeting.is_estimate && props.meeting.assignment_title !== '')
    {
      return (
        <td className="py-4 px-6">Assignment : {props.meeting.assignment_title}</td>
      )
      
    }
    else
    {
      return (
        <td className="py-4 px-6"></td>
      )
    }
    
  }
  
  return (
    <div className="page-container relative h-full flex flex-auto flex-col">
        <div className="pt-8 pb-4 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-6">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-4">
                  <div className="">
                      <h3 className='text-3xl'>Reports</h3>
                      <h4 className='text-lg'>{participantNameSate} - <span className='text-gray-500 text-sm'>{totalHoursMinutes}</span></h4>
                  </div>
                  {!isEmptyMeetings && (
                  <div className="flex items-center gap-2">
       
                      <div className="">
                      <ExportExcel excelData={filteredMeetings} fileName={"Report_"+participantNameSate.slice(0,1).toUpperCase() + participantNameSate.slice(1, participantNameSate.length)+"_"+dateFormat(new Date(),"dd_mm_yyyy_HH_MM")} />

                       
                      </div>
                  </div>
                  )}
              </div>
          </div>
        </div>

        <div className="container mx-auto h-full px-6 py-6">
        {isLoading && (
            <div className="center flex items-center">                
                <Spinner size="3.25rem" />
            </div>
          )} 

          {error && (
            <div>
              <Alert type="danger" closable  >
                  {error}
              </Alert>
          </div>
          )} 
          {errorMessage && (
            <div>
                <Alert type="danger" closable  >
                    {errorMessage}
                </Alert>
            </div>
          )} 
          {isEmptyMeetings && (
            <div>
                <Alert type="danger" closable  >
                No meeting found
                </Alert>
            </div>
          )} 

        {successMessage && (    
          <div>
              <Alert type="success" closable  >
                  {successMessage}
              </Alert>
          </div>
        )}

        {!isLoading && loadedMeetings && !isEmptyMeetings  && ( 
        <Card>
          <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">Client</th>
                  <th scope="col" className="py-3 px-6">Project</th>
                  <th scope="col" className="py-3 px-6">Assignment/Estimate</th>
                  <th scope="col" className="py-3 px-6">Meeting Objective / Title</th>
                  <th scope="col" className="py-3 px-6">Meeting Duration in minutes</th>
                  <th scope="col" className="py-3 px-6">Meeting person</th>
                  <th scope="col" className="py-3 px-6">Medium</th>
                  <th scope="col" className="py-3 px-6">Meeting date and time</th>
                </tr>
            </thead>
            <tbody>
            { !isLoading && loadedMeetings && loadedMeetings.data.map((meeting, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="py-4 px-6">{meeting.client_name}</td>
                  <td className="py-4 px-6">{meeting.project_title}</td>
                  <AssignmentEstimateTitle meeting={meeting} />  
                  <td className="py-4 px-6">{meeting.meeting_title}</td>
                  <td className="py-4 px-6">{meeting.meeting_duration} minutes</td>
                  <td className="py-4 px-6">
                  <div className="avatar-group avatar-group-chained">
                            { meeting.person_images && Object.entries(JSON.parse(meeting.person_images)).map(([key, value]) => (
                                <div className="tooltip-wrapper " >
                                  <div className="avatar avatar-circle cursor-pointer" style={{width: "30px", height: "30px"}}>
                                  {value ? ( 
                                    <img src={value} alt="" className='avatar-img avatar-circle' />
                                    ) : ( 
                                      <img src={noUserImage} alt="" className='avatar-img avatar-circle' />
                                    )}  
                                  </div>
                                  
                              </div>
                              ))
                            }
                            
                            
                          </div>
                  </td>
                  <td className="py-4 px-6">{meeting.meeting_medium.slice(0,1).toUpperCase() + meeting.meeting_medium.slice(1, meeting.meeting_medium.length) }</td>
                  <td className="py-4 px-6">{dateFormat(meeting.meeting_date_time, "dddd, mmmm d, yyyy HH:MM TT") }</td>
              </tr>
              )
            )}
 
 
            </tbody>
        </table>
        </div>
        </Card>
        )}
        { !isLoading && !isEmptyMeetings && loadedMeetings && loadedMeetings.last_page > 1 && (
          <div>              
            <Pagination pageSize={loadedMeetings.per_page} currentPage={loadedMeetings.current_page} total={loadedMeetings.total} onChange={onPaginationChange} />
          </div>
          
        )}
        </div>
      </div>
  )
}

export default ReportList