import { BrowserRouter} from "react-router-dom"
import Routing from "./components/routes";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

function App() {
  const { token, login, logout, userId } = useAuth(); 

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </AuthContext.Provider>
    
  );
}

export default App;